.image-part p {
    margin: 0;
    color: #444;
}

.image-part {
    padding-bottom: 0.5rem;
    text-align: center;
}

.icon {
    margin: 1rem;
    width: 5rem;
    height: auto;
    border-radius: 50%;
    box-shadow: 0.2rem 0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}

#plus {
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.2);
    border: solid 0.1rem #555;
    padding: none;
    color: #fff;
}

@media screen and (max-width: 600px) {
    .image-part {
        display: inline-block;
        text-align: center;
        color: aliceblue;
    }
    .icon {
        margin: 0 0.5rem;
        width: 5rem;
        height: auto;
        box-shadow: 0.2rem 0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
    }
}
