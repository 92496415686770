.main {
    margin: 0;
}

@media screen and (max-width: 480px) {
    .main {
        margin: 0;
        padding: 0;

        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
}
