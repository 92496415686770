.header-icon {
    display: grid;
    padding: 2rem 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
}

a {
    text-decoration: none;
}

.header-icon p {
    margin: 1rem 0;
    font-size: 1.1rem;
    color: rgba(33, 99, 99, 1);
}

.content::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1300px) {
    .header-icon {
        display: grid;
        padding: 2rem 0rem;
        grid-template-columns: 1fr 1fr 1fr;
        overflow: auto;
        white-space: nowrap;
    }
}

/* @media screen and (max-width: 1000px) {
    .header-icon {
        display: grid;
        padding: 2rem 0rem;
        grid-template-columns: 1fr 1fr ;
        overflow: auto;
        white-space: nowrap;
    }
} */

@media screen and (max-width: 600px) {
    .header-icon {
        display: block;
        padding: 2rem 0;
        overflow: auto;
        white-space: nowrap;
    }
}
