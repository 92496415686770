.header {
    margin: 0;
    margin-top: 3rem;
}

@media screen and (max-width: 480px) {
    .header {
        margin: 0;
        padding: 0;
        padding-top: 1rem;

        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
}
