.high {
    padding-top: 6rem;
}

.low {
    padding-top: 4rem;
}

.absent {
    padding-top: 2rem;
}
